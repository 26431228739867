import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '@store/base-query';
import AuthResponse from './interfaces/auth-response.interface';

export interface LoginRequest {
  username: string;
  password: string;
}

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'authentication/admin',
        method: 'POST',
        body: {
          ...credentials,
        },
        credentials: 'include',
      }),
    }),
    loginManager: builder.mutation<AuthResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'authentication/manager',
        method: 'POST',
        body: {
          ...credentials,
        },
        credentials: 'include',
      }),
    }),
    logout: builder.mutation<void, { refreshToken: string; }>({
      query: ({ refreshToken }) => ({
        url: 'authentication/revoke',
        method: 'POST',
        credentials: 'include',
        body: {
          refreshToken,
        }
      }),
    }),
  }),
});

export const { useLoginMutation, useLoginManagerMutation, useLogoutMutation } = authApi;

export default authApi;
