import { Id } from '@src/declarations';
import { Manager } from '../managers';

export interface Admin {
  id: Id;
  username: string;
}

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  admin: Admin | null;
  manager: Manager | null;
}

const authInitialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  admin: null,
  manager: null,
};

export default authInitialState;
