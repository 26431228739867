import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import authInitialState from './auth.initial-state';
import authApi from './auth.api';
import AuthResponse from './interfaces/auth-response.interface';
import { RootState } from '../../store-config/store';

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    refreshTokens: (state, { payload }: PayloadAction<AuthResponse>) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      localStorage.setItem('refreshToken', payload.refreshToken)
    },
    loggedOut: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      localStorage.setItem('refreshToken', '');
      state.admin = null;
      state.manager = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        localStorage.setItem('refreshToken', payload.refreshToken)
        state.admin = payload.admin;
      })
      .addMatcher(authApi.endpoints.loginManager.matchFulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        localStorage.setItem('refreshToken', payload.refreshToken)
        state.manager = payload.manager;
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
        state.accessToken = null;
        state.refreshToken = null;
        localStorage.setItem('refreshToken', '');
        state.admin = null;
        state.manager = null;
      })
      .addMatcher(authApi.endpoints.logout.matchRejected, (state) => {
        state.accessToken = null;
        state.refreshToken = null;
        localStorage.setItem('refreshToken', '');
        state.admin = null;
        state.manager = null;
      });
  },
});

export const { refreshTokens, loggedOut } = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectRole = (state: RootState) => (state.auth.admin ? 'admin' : 'manager');
export const selectAdmin = (state: RootState) => state.auth.admin;
export const selectManager = (state: RootState) => state.auth.manager;
export const selectIsAuthenticated = (state: RootState) => !!state.auth.accessToken;

export default authSlice.reducer;
