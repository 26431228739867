// You can customize the template with the help of this file
interface ThemeConfig {
  app: {
    appName: string;
    appLogoImage: string;
  };
  layout: {
    isRTL: boolean;
    skin: 'light' | 'dark' | 'bordered' | 'semi-dark';
    routerTransition: 'fadeIn' | 'fadeInLeft' | 'zoomIn' | 'none';
    type: 'vertical' | 'horizontal';
    contentWidth: 'full' | 'boxed';
    menu: {
      isHidden: boolean;
      isCollapsed: boolean;
    };
    navbar: {
      type: 'static' | 'sticky' | 'floating' | 'hidden';
      backgroundColor: string;
    };
    footer: {
      type: 'static' | 'sticky' | 'hidden';
    };
    customizer: boolean;
    scrollTop: boolean;
  };
}

//Template config options
const themeConfig: ThemeConfig = {
  app: {
    appName: 'Metrazhi',
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    appLogoImage: require('@src/assets/images/logo/logo.svg').default,
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
  },
};

export default themeConfig;
