import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRefresh } from '@store/base-query';

const baseApi = createApi({
  baseQuery: baseQueryWithRefresh,
  reducerPath: 'base',
  tagTypes: [
    'Managers',
    'Banners',
    'Buildings',
    'Appartments',
    'Layouts',
    'Documents',
    'CardTypes',
    'News',
    'Videos',
    'Notifications',
    'Purchases',
    'Clients',
    'Contact',
    'Peoples',
    'ConsultationRequests',
  ],
  endpoints: () => ({}),
});

export default baseApi;
